<template>
	<!-- 趋势分析 -->
	<div class="flex chartDiv" ref="chartDiv">
		<div class="tendencyChats" :class="isFullScreen?'fsCharts':''" ref="tendencyChats"></div>
		<div style="font-weight: 400;color: rgba(255, 255, 255, 0.65);font-size: 13px;position: absolute;left: 18px;">
			<span>数据来源：{{data.dataSrc==0?'圣名科技':(data.dataSrc==1?'西安因联':(data.dataSrc==2?'容知日新':''))}} </span>
		</div>
		<div class="bg99" style="color: #ffffff;">
			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">
					全屏
				</div>
				<div class="btnSome" v-else @click="exitFullScreen()">
					退出
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	let tendencyChats=null;
	let option ={};
	export default {
		name: "tendencyChats",
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return "";
				}
			}
		},
		data() {
			return {
				chatsIndex: this.activeMeasDef,
				isFullScreen: false,
				data: {}
			};
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.init();
			},
		},
		mounted() {
			let that = this;
			watchFull(this.$refs.chartDiv, ifs => {
				if (tendencyChats) {
					tendencyChats.resize();
				}
				if(!ifs){
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (tendencyChats) {
				tendencyChats.dispose();
				tendencyChats = null;
			}
			option = {};
		},
		methods: {
			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			chatsChagne(chatsIndex) {
				if (chatsIndex) {
					this.chatsIndex = chatsIndex;
				}
				this.$emit('chatsChagne', this.chatsIndex)
			},
			initOption() {
				var titles = [];
				var grids = [];
				var xAxis = [];
				var yAxis = [];
				var series = [];
				var xAxisIndex = [];
				for (var i = 0; i < 3; i++) {
					titles.push({
						left: "center",
						top: (i * (90 / 3)) + 5 + "%",
						text: this.data.name[i],
					})
					grids.push({
						top: (i * (90 / 3)) + 8 + "%",
						left: "50px",
						right: "100px",
						bottom: (3 - 1 - i) * (90 / 3) + 8 + "%",
						containLabel: true
					})
					xAxis.push({
						name: this.data.unit[i].x,
						nameTextStyle: {
							color: "#D4D5D7"
						},
						// minInterval: 1,
						type: "time",
						//x轴在 grid 区域中的分隔线。
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						//x坐标轴轴线相关设置。
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						// 坐标轴刻度标签的相关设置。
						axisLabel: {
							// rotate: -30,
							interval: 'auto',
							color: "#D4D5D7"
						},
						gridIndex: i
					})
					let yax = {
						name: this.data.unit[i].y,
						nameTextStyle: {
							color: "#D4D5D7"
						},
						type: "value",
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						axisLabel: {
							color: "#D4D5D7"
						},
						gridIndex: i,
					} 
						yax.max = this.data.calc[i].max
					let obj = {
						name: this.data.name[i],
						data: this.data.series[i],
						type: "line",
						smooth: true,
						symbolSize: 0,
						xAxisIndex: i,
						yAxisIndex: i,
						itemStyle: {
							normal: {
								color: '#15B931',
								lineStyle: {
									width: 0.8, // 0.1的线条是非常细的了
									color: '#15B931',
								}
							}
						},
					};
					if (i == 1) {
						let warnData = [];
						for (let j = 1; j <= 3; j++) {
							let warnColor = `alarmColor${j}`;
							let warnLine = `alarmValue${j}`;
							let warnName = `alarmName${j}`;
							warnData.push({
								name: this.data.alarm[i][warnName],
								yAxis: this.data.alarm[i][warnLine],
								lineStyle: {
									type: 'solid',
									color: this.data.alarm[i][warnColor]
								},
								label: {
									color: "#ffffff",
									formatter: (params) => {
										return params.name + ":" + params.value;
									}
								}
							}, );
						}
						obj.markLine = {
							data: warnData,
						}
					}
					yAxis.push(yax);
					series.push(obj);
					xAxisIndex.push(i);
				}
				option = {
					backgroundColor: '#000B17',
					title: titles,
					tooltip: {
						confine: true, // 加入这一句话
						trigger: 'axis',
						backgroundColor: 'rgba(34, 34, 34, 0.5)',
						borderWidth: 0,
						textStyle: {
							color: 'rgba(255, 255, 255, 1.0)'
						},
						axisPointer: {
							animation: false
						},
						position: function(pos, params, el, elRect, size) {
							var obj = {
								top: 10
							};
							obj['left'] = pos[0] + 10;
							obj['top'] = pos[1] - 60;
							return obj;
						},
						formatter: function(param) {
							let text = [];
							text.push(param[0].name + "<br/>");
							for (let item of param) {
								text.push(item.seriesName + "：" + item.value + "<br/>");
							}
							return text.join('');
						}
					},
					toolbox: {
						show: true,
						top: 20,
						right: 20,
						zlevel: 899,
						feature: {
							dataZoom: {
								yAxisIndex: 'none',
								brushStyle: {
									color: "rgba(35, 136, 255, 0.1)",
									shadowBlur: 2
								}
							},
							restore: {}
						}
					},
					axisPointer: {
						type: 'line',
						link: [{
							xAxisIndex: 'all'
						}],
						label: {
							show: false
						}
					},
					dataZoom: [{
							type: 'slider',
							show: true,
							xAxisIndex: xAxisIndex,
							start: 0,
							end: 100,
							filterMode: 'empty',
						},
						{
							type: "inside",
							xAxisIndex: xAxisIndex,
							start: 0,
							end: 100,
						},
					],
					grid: grids,
					xAxis: xAxis,
					yAxis: yAxis,
					series: series,
				};
			},
			init() {
				this.data = this.chatsData.data;
				this.initOption();
				tendencyChats = this.$echarts.init(this.$refs.tendencyChats);
				tendencyChats.setOption(option, true);
			},
		},
	};
</script>
<style lang='scss' scoped>
	.chartDiv {
		.tendencyChats {
			height: 85vh;
			width: 100%;
		}

		.tendencyChats.fsCharts {
			height: 100vh;
			width: 100%;
			background-color: #000B17;
		}

		.controlFrame-item {
			width: 150px
		}

		.active {
			background-color: #2388ff;
		}

		.timeDomainsChats {
			height: 80vh;
			width: 100%;
			background-color: #000B17;
		}

		.bg99 {
			background-color: #000B17;
		}

		.btnSome {
			height: 60px;
			width: 60px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			cursor: pointer;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}


		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
			width: 60px;
		}

	}
</style>
