<template>
	<div class="flex chartDiv" ref="chartDiv">
		<div style="width: 100%; overflow: hidden;">
			<div class="pinpuChats" :class="isFullScreen ? 'fsCharts' : ''" ref="pinpuChats" @click="clearRightMenu()">
			</div>
			<div class="pinpuChatsTree">
				<div class="name">特征频率</div>
				<el-table :data="data.groupAreas" ref="product" style="width: 100%;margin-bottom: 20px;" row-key="uuid"
					border :tree-props="{children: 'children',hasChildren: 'hasChildren'}"
					@selection-change="handleSelectionChange" @select-all="selectAllRow" @select="selectRow"
					cell-class-name="cellStyle" default-expand-all>
					<el-table-column type="selection" width="55" align="center">
					</el-table-column>
					<el-table-column prop="name" label="零部件">
					</el-table-column>
					</el-table-column>
					<el-table-column prop="plMs" label="频率描述" align="center">
					</el-table-column>
					<el-table-column prop="tzPl" label="特征频率" width="100" align="center">
					</el-table-column>
					<el-table-column prop="newRemark" label="备注">
					</el-table-column>
					<el-table-column label="操作" width="120" align="center">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="openTZPL(scope.row)">
								编辑
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<div
			style="font-weight: 400;color: rgba(255, 255, 255, 0.85);font-size: 16px;position: absolute;left: 18px;top: 16px;">
			<span style="margin-right: 20px;">采样时间：{{ data.time }}</span>
			<span style="margin-right: 20px;" v-if="chartName == '时域波形' || chartName == '频谱分析'">{{ data.rms?'总值：'+data.rms:'' }}
				{{ data.unit ? data.unit.y : '' }}</span>

			<span @click="SetBiaozhuVisible = true" title="点击修改标注" style="cursor: pointer;" v-if="chartName == '时域波形' || chartName == '频谱分析'">标注{{': '+biaozhuContext}}</span>
		</div>

		<div class="topTip" :title="msgText">
			<span
				style="margin-right: 10px;">数据来源：{{ data.dataSrc == 0 ? '圣名科技' : data.dataSrc == 1 ? '西安因联' : data.dataSrc == 2 ? '容知日新' : '' }};</span>
			<span style="margin-right: 10px;" v-if="data.measComm">零部件描述：{{ data.measComm }}</span>
		</div>
		<div style="color:#ffffff;position: absolute;right: 100px;" v-if="chartName == '时域波形' || chartName == '频谱分析'">
			<span>运行转速：{{ data.rpm }} RPM</span>
		</div>
		
		<el-dialog title="标注" :visible.sync="SetBiaozhuVisible" width="20%" :modal="false" :show-close="false">
			<el-input style="width: 90%; margin: 0 5%;" focus v-model="biaozhuContext" placeholder="请输入内容"></el-input>
			<span slot="footer">
				<el-button @click="SetBiaozhuVisible = false">取 消</el-button>
				<el-button type="primary" @click="setBiaozhu()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- //隐藏的canvas -->
		<!-- <canvas id="myDownCanvas" style="display: none;"></canvas> -->
		<div class="bg99" v-if="isShowBtns">
			<!-- <div class="rightBtn mb-10">
				<div class="btnSome" @click="openTZPL()">频率</div>
			</div> -->
			<div class="rightBtn" v-if="isShowCursor">
				<div class="btnSome" :class="{ active: this.btnIndex === 1 }" @click="changeBtn(1)">单游</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 2 }" @click="changeBtn(2)">双游</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 3 }" @click="changeBtn(3)">倍频</div>
				<div class="btnSome" :class="{ active: this.btnIndex === 4 }" @click="changeBtn(4)">边频</div>
				<div class="btnSome" :class="{ active: this.markIndex === 2 }" @click="changeMark(5)">清除</div>
			</div>

			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.chatsIndex == '加速度' }" @click="chatsChagne('加速度')">加速度</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '速度' }" @click="chatsChagne('速度')">速度</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '位移' }" @click="chatsChagne('位移')">位移</div>
			</div>

			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">全屏</div>
				<div class="btnSome" v-else @click="exitFullScreen()">退出</div>
			</div>
			
			<div class="rightBtn mt-10">
				<div class="btnSome fontSize12" @click="download()">下载数据</div>
			</div>
			<div ref="rightMenu" class="rightClickMenu" style="display: none;">
				<div>
					<el-button type="text" @click="openSetPoint()">标记</el-button>
					<el-button type="text" @click="openClearOnePoint()">取消标记</el-button>
					<el-button type="text" @click="openClearAllPoint()">取消全部标记</el-button>
				</div>
			</div>
			<el-dialog title="标记" :visible.sync="SetPointVisible" width="20%" :modal="false" :show-close="false">
				<el-input style="width: 90%; margin: 0 5%;" focus v-model="pointContext" placeholder="请输入内容"></el-input>
				<span slot="footer">
					<el-button @click="SetPointVisible = false">取 消</el-button>
					<el-button type="primary" @click="setPoint()">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="取消标记" :visible.sync="ClearOnePointVisible" width="20%" :modal="false" :show-close="false">
				<span style="width: 90%; margin: 0 5%;">{{ '确认删除 ' + onePoint.value + ' 此标记吗？' }}</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ClearOnePointVisible = false">取 消</el-button>
					<el-button type="primary" @click="clearOnePoint(onePoint, onePoint.index)">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="取消全部标记" :visible.sync="ClearAllPointVisible" width="20%" :modal="false"
				:show-close="false">
				<span style="width: 90%; margin: 0 5%;">取消全部标记后，所有标记将消失！</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ClearAllPointVisible = false">取 消</el-button>
					<el-button type="primary" @click="clearAllPoint()">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="备注" :visible.sync="visible" width="20%" :modal="false" :show-close="false">
				<!-- <span>
					<el-tree :data="data.faultComp" ref="tree" show-checkbox node-key="id" default-expand-all
						:props="defaultProps" style="max-height: 300px;overflow: auto;"></el-tree>
				</span> -->
				<el-input style="width: 90%; margin: 0 5%;" focus v-model="TZPLremark" placeholder="请输入备注内容"></el-input>
				<span slot="footer">
					<el-button @click="visible = false">取消</el-button>
					<el-button type="primary" @click="undateMark">确定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	let pinpuChats = null;
	let option = {};
	let timer = null;
	export default {
		name: 'pinpuChats',
		data() {
			return {
				btnIndex: '',
				markIndex: '',
				chatsIndex: this.activeMeasDef,
				xAxisData: [],
				xAxisArr: [],
				chatsList: [],
				dataZoomStart: 0,
				dataZoomEnd: 100,
				pointContext: '',
				biaozhuContext: '',
				markList: [],
				currentPoint: {},
				seriesPoints: [],
				isFullScreen: false,
				data: {},
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				visible: false,
				msgText: '',
				tagList: [],
				SetPointVisible: false,
				SetBiaozhuVisible: false,
				ClearOnePointVisible: false,
				ClearAllPointVisible: false,
				onePoint: '',
				TZPLremark: '',
				TZPLId: '',
				biaozhuId:null
			};
		},
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return '';
				}
			},
			isShowCursor: {
				type: Boolean,
				default () {
					return true;
				}
			},
			isShowBtns: {
				type: Boolean,
				default () {
					return true;
				}
			},
			chartName: {
				type: String,
				default () {
					return '';
				}
			}
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.chatsList = val;
				this.resetChecked();
				this.init();
				this.getBiaozhu()
			}
		},
		mounted() {
			// this.getTree();
			let that = this;
			watchFull(this.$refs.chartDiv, ifs => {
				if (pinpuChats) {
					pinpuChats.resize();
				}
				if (!ifs) {
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (pinpuChats) {
				pinpuChats.dispose();
				pinpuChats = null;
			}
			option = {};
		},
		methods: {
			
			//设置标注
			setBiaozhu(){
				let param = {
					remark:this.biaozhuContext,
					measTime:this.data.time,
					measId:this.data.measId,
				}
				if(this.biaozhuId){
					param.id = this.biaozhuId
				}
				this.$postJson('/backend-api/eqp/measRemark/addData',param).then(data=>{
					if(data.code!=1000){
						this.$message.error(data.msg);
						return
					}
					this.$message.success('保存成功');
				})
				
				this.SetBiaozhuVisible = false;
			},
			
			getBiaozhu(){
				this.biaozhuId = null;
				this.biaozhuContext ='';
				let param = {
					measTime:this.data.time,
					measId:this.data.measId,
				}
				this.$post('/backend-api/eqp/measRemark/getDataByMeasAndTime',param).then(data=>{
					if(data.code!=1000){
						this.$message.error(data.msg);
						return
					}
					if(data.data){
						this.biaozhuId = data.data.id
						this.biaozhuContext = data.data.remark;
					}
					
				})
			},
			openTZPL(row) {
				// if (this.data.rpm == 0) {
				// 	this.$message({
				// 		message: '当前转速为0，不可操作',
				// 		type: 'warning'
				// 	});
				// 	return;
				// }
				console.log(row)
				this.TZPLId = row.id;
				if (row.comp_type_id != undefined && row.comp_type_id != null) {
					this.TZPLremark = row.newRemark
					this.visible = !this.visible;
				}

				// if (this.visible) {
				// 	this.getTree();
				// }
			},
			//下载原始数据
			download(){
				let param = {
					time:this.data.time,
					meas_id:this.data.measId,
				}
				this.$download('/data-api/open/vib/download/excel', param).then(res => {
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					})
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.download = '('+this.data.time+')'+this.data.root + '.xlsx';
					link.href = url;
					document.body.appendChild(link);
					link.click();
				})
			},
			// 请求设备树
			getTree() {
				this.$get('/backend-api/web/monitor/tree', this.from).then(res => {
					if (res.code == 1000) {
						this.treeData = res.data;
						this.$nextTick(() => {
							this.$refs.tree.setCheckedNodes(this.treeData);
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//清空树
			resetChecked() {
				this.markList = [];
			},

			//更新特征频率的备注

			undateMark() {
				this.$post('/backend-api/web/spec/vib/updateRemark', {
					id: this.TZPLId,
					remark: this.TZPLremark
				}).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return
					}
					this.$message.success('保存成功');
					this.visible = !this.visible;
					this.$emit('chatsChagne', this.chatsIndex);
				})
			},

			// <==========================多选模块============================================>

			// 勾选父节点时，子层级一起勾选或一起取消勾选
			selectRow(selection, row) {
				let data = selection.some((item) => {
					return row.id === item.id;
				});
				if (data) {
					// 勾选节点时
					if (row.children) {
						this.setChildren(row.children, true);
					}
				} else {
					// 取消勾选节点时
					if (row.children) {
						this.setChildren(row.children, false);
					}
				}
			},
			// 全选按钮
			selectAllRow(selection) {
				// 全选
				let isSelect = selection.some((item) => {
					let tableDataIds = this.data.groupAreas.map((data) => data.id);
					if (tableDataIds.indexOf(item.id) !== -1) {
						return true;
					} else {
						return false;
					}
				});
				// 全不选
				let isCancel = !this.data.groupAreas.every((item) => {
					let selectIds = selection.map((data) => data.id);
					if (selectIds.indexOf(item.id) !== -1) {
						return true;
					} else {
						return false;
					}
				});
				if (isSelect) {
					selection.map((item) => {
						if (item.children) {
							this.setChildren(item.children, true);
						}
					});
				}
				if (isCancel) {
					this.data.groupAreas.map((item) => {
						if (item.children) {
							this.setChildren(item.children, false);
						}
					});
				}
			},
			// 父节点含多个子层级
			setChildren(children, type) {
				children.map((item) => {
					this.toggleSelection(item, type);
					if (item.children) {
						this.setChildren(item.children, type);
					}
				});
			},

			toggleSelection(row, select) {
				if (row) {
					this.$nextTick(() => {
						this.$refs.product.toggleRowSelection(row, select);
					});
				}
			},
			handleSelectionChange(val) {
				this.setMarkPoint(val)
			},
			setMarkPoint(val) {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				this.markList = [];
				for (let item of val) {
					let markPoint = {
						xAxis: (item.tzPl) * 1,
						showValue: item.plMx,
						lineStyle: {
							type: 'dashed'
						}
					};
					this.markList.push(markPoint);
				}
				option.series[1].markLine = {
					itemStyle: {
						normal: {
							color: 'rgba(255, 38, 38, 1)'
						}
					},
					label: {
						show: true,
						color: '#ffffff',
						formatter: params => {
							return params.data.showValue;
						}
					},
					data: this.markList
				};
				pinpuChats.setOption(option, true);

			},


			// <=====================================================================================>

			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			//关闭右键按钮
			clearRightMenu() {
				this.$refs.rightMenu.style['display'] = 'none';
				this.$refs.rightMenu.style['left'] = '9999px';
				this.$refs.rightMenu.style['top'] = '9999px';
			},
			//打开标记弹窗
			openSetPoint() {
				this.clearRightMenu();
				this.SetPointVisible = true;
			},
			//打开取消标记弹窗
			openClearOnePoint() {
				for (let i = 0, length = option.series[0].markPoint.data.length; i < length; i++) {
					let item = option.series[0].markPoint.data[i];
					if (item.xAxis - this.currentPoint.name <= 5 && item.xAxis - this.currentPoint.name >= -5) {
						let arr = this.lookup(option.series[0].markPoint.data, this.currentPoint.name * 1);
						this.clearRightMenu();
						this.ClearOnePointVisible = true;
						this.onePoint = arr[0];
						this.onePoint.index = arr[1];
						return;
					}
				}
			},
			lookup(arr, num) {
				var index = 0; // 保存最接近数值在数组中的索引
				var d_value = Number.MAX_VALUE; // 保存差值绝对值，默认为最大数值
				for (var i = 0; i < arr.length; i++) {
					var new_d_value = Math.abs((arr[i].value * 1) - num); // 新差值
					if (new_d_value <= d_value) {
						// 如果新差值绝对值小于等于旧差值绝对值，保存新差值绝对值和索引
						if (new_d_value === d_value && (arr[i].value * 1) < arr[index]) {
							// 如果数组中两个数值跟目标数值差值一样，取大
							continue;
						}
						index = i;
						d_value = new_d_value;
					}
				}
				return [arr[index], index]; // 返回最接近的数值
			},
			//打开取消全部标记弹窗
			openClearAllPoint() {
				this.clearRightMenu();
				this.ClearAllPointVisible = true;
			},
			chatsChagne(chatsIndex) {
				this.chatsIndex = chatsIndex;
				this.$emit('chatsChagne', chatsIndex);
			},
			//初始化数据,将数据都用isSelect标记一下，isSelect为false不选中、true选中、half半选
			initData(data) {
				data.forEach((item) => {
					item.isSelect = false; //默认为不选中
					if (item.children && item.children.length) {
						this.initData(item.children);
					}
				});
			},

			initOption() {
				var grids = [];
				var xAxis = [];
				var yAxis = [];
				var series = [];
				var xAxisIndex = [];
				var titles = [];
				var length = this.chatsList.data.length;
				grids.push({
					top: '15%',
					left: '30px',
					right: '80px',
					bottom: '10%',
					containLabel: true
				});
				xAxis.push({
					name: this.chatsList.data.unit[0].x,
					nameTextStyle: {
						color: '#D4D5D7'
					},
					value: this.chatsData.data.xData,
					type: 'category',
					//x轴在 grid 区域中的分隔线。
					splitLine: {
						show: true,
						lineStyle: {
							color: '#384049',
							type: 'dashed'
						}
					},
					//x坐标轴轴线相关设置。
					axisLine: {
						show: true,
						lineStyle: {
							color: '#384049'
						},
						onZero: false
					},
					// 坐标轴刻度标签的相关设置。
					axisLabel: {
						// rotate: -30,
						interval: 'auto',
						color: '#D4D5D7'
					},
					min: 'dataMin',
					max: 'dataMax'
				});
				let yax = {
					name: this.chatsList.data.unit[0].y,
					nameTextStyle: {
						color: '#D4D5D7'
					},
					type: 'value',
					splitLine: {
						show: true,
						lineStyle: {
							color: '#384049',
							type: 'dashed'
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: '#384049'
						},
						onZero: false
					},
					axisLabel: {
						color: '#D4D5D7'
					}
				};
				yAxis.push(yax);
				let PointArr = {
					itemStyle: {
						color: '#66ccff'
					},
					label: {
						color: '#ffffff'
					},
					data: []
				};
				if (this.chatsList.data.tags.length > 0) {
					for (let es = 0, lenes = this.chatsList.data.tags.length; es < lenes; es++) {
						this.tagList.push(this.chatsList.data.tags[es].id);
						PointArr.data.push({
							value: this.chatsList.data.tags[es].value,
							xAxis: String(this.chatsList.data.tags[es].x),
							yAxis: String(this.chatsList.data.tags[es].y),
							ids: String(this.chatsList.data.tags[es].id)
						});
					}
				}

				series.push({
					data: this.chatsData.data.series[0],
					type: 'line',
					symbolSize: 1,
					showSymbol: false,
					showAllSymbol: false,
					itemStyle: {
						normal: {
							color: '#15B931',
							lineStyle: {
								width: 0.8, // 0.1的线条是非常细的了
								color: '#15B931'
							}
						}
					},
					markPoint: PointArr
				}, {
					type: 'line',
					showSymbol: false
					// data: this.chatsData.data.xData
				});
				option = {
					backgroundColor: '#000B17',
					title: titles,
					tooltip: {
						confine: true, // 加入这一句话
						trigger: 'axis',
						backgroundColor: 'rgba(34, 34, 34, 0.5)',
						borderWidth: 0,
						textStyle: {
							color: 'rgba(255, 255, 255, 1.0)'
						},
						axisPointer: {
							animation: false
						},
						position: function(pos, params, el, elRect, size) {
							var obj = {
								top: 10
							};
							obj['left'] = pos[0] + 10;
							obj['top'] = pos[1] - 60;
							return obj;
						},
						formatter: param => {
							let text = [];
							text.push(this.chatsData.data.tooltip[0].x + '：' + param[0].value[0] + this.chatsData
								.data.unit[0].x + '<br/>');
							if (param.length == 1) {
								text.push(this.chatsData.data.tooltip[0].y + '：' + param[0].value[1] + this
									.chatsData.data.unit[0].y + '<br/>');
							} else {
								for (let i = 0; i < param.length; i++) {
									let item = param[i];
									text.push(this.chatsData.data.tooltip[0].y + (i + 1) + '：' + item.value[1] +
										this.chatsData.data.unit[0].y + '<br/>');
								}
							}
							return text.join('');
						}
					},
					toolbox: {
						show: true,
						top: 20,
						right: 20,
						zlevel: 899,
						feature: {
							dataZoom: {
								yAxisIndex: 'none',
								brushStyle: {
									color: 'rgba(35, 136, 255, 0.1)',
									shadowBlur: 2
								}
							},
							restore: {},
							saveAsImage: {
								title: '下载图谱',
								pixelRatio: 2,
								name: `${this.chatsData.data.root}/${this.chatsData.type}/${this.chatsData.time}`
							}
						}
					},
					grid: grids,
					xAxis: xAxis,
					yAxis: yAxis,
					series: series,
					axisPointer: {
						type: 'line',
						link: [{
							xAxisIndex: 'all'
						}],
						label: {
							show: false
						}
					},
					dataZoom: [{
							type: 'slider',
							filterMode: 'empty',
							show: true,
							realtime: true,
							start: this.dataZoomStart,
							end: this.dataZoomEnd
						},
						{
							type: 'inside',
							realtime: true
						}
					]
				};
			},
			init() {
				this.data = this.chatsData.data;
				this.data.measComm = '';
				this.msgText = '';
				this.msgText += '数据来源：';
				if (this.data.dataSrc == 0) {
					this.msgText += '圣名科技';
				} else if (this.data.dataSrc == 1) {
					this.msgText += '西安因联';
				} else if (this.data.dataSrc == 2) {
					this.msgText += '容知日新';
				}
				this.msgText += '; ';
				if (this.data.compComm) {
					for (let item of this.data.compComm) {
						this.data.measComm += item.comm + ' ';
					}
				}
				this.msgText += '零部件描述：' + (this.data.measComm ? this.data.measComm : '') + ' ';
				// this.clearAllPoint();
				this.initOption();
				pinpuChats = this.$echarts.init(this.$refs.pinpuChats);
				pinpuChats.setOption(option, true);
				//缩放时记录缩放的范围
				pinpuChats.on('dataZoom', event => {
					if (event.batch) {
						this.dataZoomStart = event.batch[0].start;
						this.dataZoomEnd = event.batch[0].end;
					} else {
						this.dataZoomStart = event.start;
						this.dataZoomEnd = event.end;
					}
				});
				//右键弹出菜单，支持点击空白处
				pinpuChats.getZr().on('contextmenu', params => {
					this.clickZr(params, () => {
						//打开右键菜单
						this.pointContext = this.currentPoint.name;
						this.$refs.rightMenu.style['display'] = 'block';
						this.$refs.rightMenu.style['left'] = params.offsetX + 30 + 'px';
						this.$refs.rightMenu.style['top'] = params.offsetY + 30 + 'px';
					});
				});
				
				window.addEventListener("resize", () => {
				  pinpuChats.resize();
				 });
			},
			//点击空白处设置数据
			clickZr(params, func) {
				const pointInPixel = [params.offsetX, params.offsetY];
				this.seriesPoints = [];
				//对series遍历
				let index = pinpuChats.convertFromPixel({
						seriesIndex: 0
					},
					[params.offsetX, params.offsetY]
				);
				let xIndex = index[0];
				let yIndex = index[1];
				let op = option;
				// 获取当前点击位置对应折线上的数据
				if (op.series[0].data[xIndex]) {
					var xData = op.series[0].data[xIndex][0];
					var yData = op.series[0].data[xIndex][1];
					//记录所有坐标系的点
					var pointObj = {
						name: xData,
						value: yData,
						xIndex: xIndex,
						yIndex: yIndex
					};
					this.seriesPoints.push(pointObj);
					//单独记录所点坐标系的点
					if (
						pinpuChats.containPixel({
								seriesIndex: 0
							},
							pointInPixel
						)
					) {
						this.currentPoint.name = xData;
						this.currentPoint.value = yData;
						this.currentPoint.xIndex = xIndex;
						this.currentPoint.yIndex = yIndex;
					}
					func();
				}
			},
			//设置标记
			setPoint() {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				let tag = {
					meas_def: this.chatsData.meas_def,
					meas_id: this.chatsData.meas_id,
					time: this.data.time,
					value: this.pointContext,
					x: this.currentPoint.name,
					y: this.currentPoint.value,
					type: this.chatsData.type
				};
				this.$postData('/backend-api/vib/spectrum/tag/add', tag).then(res => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
						let data = [];
						this.tagList = [];
						this.chatsList.data.tags = res.data;
						if (res.data.length > 0) {
							for (let i = 0, lenes = res.data.length; i < lenes; i++) {
								this.tagList.push(res.data[i].id);
								data.push({
									value: res.data[i].value,
									xAxis: String(res.data[i].x),
									yAxis: String(res.data[i].y),
									ids: String(res.data[i].id)
								});
							}
						}
						option.series[0].markPoint.data = data;
						pinpuChats.setOption(option, true);
						this.SetPointVisible = false;
						return;
					}
					this.$message.error(res.msg);
				});
			},
			//清除单个标记
			clearOnePoint(item, i) {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				this.$postData('/backend-api/vib/spectrum/tag/remove-one?id=' + item.ids).then(res => {
					if (res.code == 1000) {
						option.series[0].markPoint.data.splice(i, 1);
						pinpuChats.setOption(option, true);
						this.$message.success(res.msg);
						this.ClearOnePointVisible = false;
						return;
					}
					this.$message.error(res.msg);
				});
			},
			//清除所有标记
			clearAllPoint() {
				option.dataZoom[0].start = this.dataZoomStart;
				option.dataZoom[0].end = this.dataZoomEnd;
				let str = this.tagList.join(',');
				this.$postData('/backend-api/vib/spectrum/tag/remove-batch?ids=' + str).then(res => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
						option.series[0].markPoint.data = [];
						pinpuChats.setOption(option, true);
						this.ClearAllPointVisible = false;
						return;
					}
					this.$message.error(res.msg);
				});
			},
			resetBaseLine() {
				if (this.xAxisData.length > 0) {
					if (this.xAxisData[0].length > 0) {
						for (var i = 0; i < this.seriesPoints.length; i++) {
							let obj = this.xAxisData[i][0];
							this.xAxisData[i] = [];
							this.xAxisData[i].push(obj);
							var series = option.series[i];
							var index = i;
							series.markLine = {
								lineStyle: {
									normal: {
										color: 'rgba(255, 38, 38, 1)'
									}
								},
								label: {
									color: '#ffffff',
									formatter: params => {
										return '(x=' + params.data.xValue + ' , y=' + params.data.yValue + ')';
									}
								},
								data: this.xAxisData[i]
							};
						}
					}
				} else {
					this.xAxisData = [];
				}
				if (this.xAxisArr.length > 0) {
					if (this.xAxisArr[0].length > 0) {
						for (var i = 0; i < this.seriesPoints.length; i++) {
							let obj = this.xAxisArr[i][0];
							this.xAxisArr[i] = [];
							this.xAxisArr[i].push(obj);
						}
					}
				} else {
					this.xAxisArr = [];
				}
			},
			changeBtn(btnIndex) {
				this.btnIndex = btnIndex;
				//切换时只显示基线
				this.resetBaseLine();
				if (btnIndex == 3) {
					for (var i = 0; i < this.seriesPoints.length; i++) {
						var item = this.seriesPoints[i];
						var arr = [];
						for (let j = 1; j < 6; j++) {
							let indexVal = this.currentPoint.xIndex * j;
							if (indexVal < option.series[i].data.length) {
								const xAxisObj = {
									xIndex: this.currentPoint.xIndex,
									yIndex: this.currentPoint.yIndex,
									xValue: this.currentPoint.name,
									yValue: item.value,
									xAxis: String(option.series[i].data[indexVal][0]),
									lineStyle: {
										type: j == 1 ? 'solid' : 'dashed'
									}
								};
								arr.push(xAxisObj);
							}
						}
						this.xAxisData = [];
						this.xAxisData.push(arr);
						var series = option.series[i];
						series.markLine = {
							lineStyle: {
								normal: {
									color: 'rgba(255, 38, 38, 1)'
								}
							},
							label: {
								color: '#ffffff',
								formatter: params => {
									if (params.dataIndex == 0) {
										return '(x=' + params.data.xValue + '，y=' + params.data.yValue + ')';
									} else {
										return '';
									}
								}
							},
							data: this.xAxisData[i]
						};
					}
				}
				pinpuChats.setOption(option, true);
				pinpuChats.getZr().off('click');
				pinpuChats.getZr().on('click', params => {
					option.dataZoom[0].start = this.dataZoomStart;
					option.dataZoom[0].end = this.dataZoomEnd;
					this.clickZr(params, () => {
						switch (this.btnIndex) {
							case 1:
								for (var i = 0; i < this.seriesPoints.length; i++) {
									var item = this.seriesPoints[i];
									var xAxisData1 = {};
									xAxisData1 = {
										xIndex: this.currentPoint.xIndex,
										yIndex: this.currentPoint.yIndex,
										xValue: this.currentPoint.name,
										yValue: item.value,
										xAxis: String(this.currentPoint.name),
										lineStyle: {
											type: 'solid'
										}
									};
									if (this.xAxisData.length <= i) {
										var arr = [];
										arr.push(xAxisData1);
										this.xAxisData.push(arr);
										this.xAxisArr = [];
										this.xAxisArr.push([this.currentPoint.xIndex]);
									} else {
										this.xAxisData[i] = [];
										this.xAxisData[i].push(xAxisData1);
									}
									var series = option.series[i];
									var index = i;
									series.markLine = {
										lineStyle: {
											normal: {
												color: 'rgba(255, 38, 38, 1)'
											}
										},
										label: {
											color: '#ffffff',
											formatter: params => {
												return '(x=' + params.data.xValue + ' , y=' +
													params.data.yValue + ')';
											}
										},
										data: this.xAxisData[i]
									};
								}
								break;
							case 2:
								//当前每一个series的游标
								for (var i = 0; i < this.seriesPoints.length; i++) {
									var item = this.seriesPoints[i];
									var xAxisObj = {
										xIndex: this.currentPoint.xIndex,
										yIndex: this.currentPoint.yIndex,
										xValue: this.currentPoint.name,
										yValue: item.value,
										xAxis: String(this.currentPoint.name),
										flag: false,
										lineStyle: {
											type: 'solid'
										}
									};
									if (this.xAxisData.length <= i) {
										var arr = [];
										arr.push(xAxisObj);
										this.xAxisData.push(arr);
										this.xAxisArr = [];
										this.xAxisArr.push([this.currentPoint.xIndex]);
									} else if (this.xAxisData[i].length == 1) {
										this.xAxisData[i][0].flag = true;
										this.xAxisData[i].push(xAxisObj);
										var t = Math.abs(this.xAxisData[i][0].xValue - this.xAxisData[i][1]
											.xValue).toFixed(2);
										var f = (1000 / t).toFixed(2);
										var amp = Math.abs(this.xAxisData[i][0].yValue - this.xAxisData[i][
											1
										].yValue).toFixed(2);
										xAxisObj = {
											xIndex: this.currentPoint.xIndex,
											yIndex: this.currentPoint.yIndex,
											xValue: this.currentPoint.name,
											yValue: item.value,
											xAxis: String(this.currentPoint.name),
											t,
											f,
											amp,
											flag: true,
											lineStyle: {
												type: 'dashed'
											}
										};
										this.xAxisData[i][1] = xAxisObj;
									} else if (this.xAxisData[i].length == 2) {
										this.xAxisData[i] = [];
										this.xAxisData[i].push(xAxisObj);
										this.xAxisArr = [];
										this.xAxisArr.push([this.currentPoint.xIndex]);
									}
									var series = option.series[i];
									series.markLine = {
										lineStyle: {
											normal: {
												color: 'rgba(255, 38, 38, 1)'
											}
										},
										label: {
											color: '#ffffff',
											formatter: params => {
												if (params.data.flag) {
													if (params.dataIndex == 1) {
														return '(△t=' + params.data.t + ',△f=' +
															params.data.f + ',△Amp=' + params.data
															.amp + ')';
													} else {
														return '';
													}
												} else {
													return '(x=' + params.data.xValue + ',y=' +
														params.data.yValue + ')';
												}
											}
										},
										data: this.xAxisData[i]
									};
								}
								break;
							case 3:
								this.xAxisData = [];
								for (var i = 0; i < this.seriesPoints.length; i++) {
									var item = this.seriesPoints[i];
									var arr = [];
									for (let j = 1; j < 6; j++) {
										let indexVal = this.currentPoint.xIndex * j;
										if (indexVal < option.series[i].data.length) {
											const xAxisObj = {
												xIndex: this.currentPoint.xIndex,
												yIndex: this.currentPoint.yIndex,
												xValue: this.currentPoint.name,
												yValue: item.value,
												xAxis: String(option.series[i].data[indexVal][0]),
												lineStyle: {
													type: j == 1 ? 'solid' : 'dashed'
												}
											};
											arr.push(xAxisObj);
										}
									}
									this.xAxisData.push(arr);
									this.xAxisArr = [];
									this.xAxisArr.push([this.currentPoint.xIndex]);
									var series = option.series[i];
									series.markLine = {
										lineStyle: {
											normal: {
												color: 'rgba(255, 38, 38, 1)'
											}
										},
										label: {
											color: '#ffffff',
											formatter: params => {
												if (params.dataIndex == 0) {
													return '(x=' + params.data.xValue + ',y=' +
														params.data.yValue + ')';
												} else {
													return '';
												}
											}
										},
										data: this.xAxisData[i]
									};
								}
								break;
							case 4:
								let xAxis = String(this.currentPoint.name);
								let indexCalc = '';
								let xAxisCalc = 1;
								for (var i = 0; i < this.seriesPoints.length; i++) {
									var item = this.seriesPoints[i];
									var xAxisObj = {
										xIndex: this.currentPoint.xIndex,
										yIndex: this.currentPoint.yIndex,
										xValue: this.currentPoint.name,
										yValue: item.value,
										xAxis: String(this.currentPoint.name),
										flag: false,
										lineStyle: {
											type: 'solid'
										}
									};
									if (this.xAxisData.length <= i) {
										var arr = [];
										arr.push(xAxisObj);
										this.xAxisData.push(arr);
										this.xAxisArr = [];
										this.xAxisArr.push([this.currentPoint.xIndex]);
									} else if (this.xAxisArr[i].length == 1) {
										this.xAxisArr[i].push(this.currentPoint.xIndex);
										this.xAxisData[i][0].flag = true;
										xAxisObj.flag = true;
										xAxisObj.lineStyle.type = 'dashed';
										this.xAxisData[i].push(xAxisObj);
										indexCalc = this.xAxisArr[i][1] - this.xAxisArr[i][0];
										xAxisCalc = Math.abs(option.series[i].data[this.xAxisArr[i][1]][
											0
										] - option.series[i].data[this.xAxisArr[i][0]][0]);
										this.xAxisData[i][0].f = Math.abs(this.xAxisData[i][0].xValue -
											this.xAxisData[i][1].xValue).toFixed(2);
										for (let j = 1; j < 4; j++) {
											let subtractValue = Number(this.xAxisArr[i][0]) - Number(
												indexCalc * j);
											let subtractValue1 = Number(this.xAxisArr[i][0]) + Number(
												indexCalc * j);
											if (
												0 < subtractValue &&
												subtractValue < option.series[i].data.length &&
												0 < subtractValue1 &&
												subtractValue1 < option.series[i].data.length
											) {
												const xAxisObj = {
													xIndex: this.currentPoint.xIndex,
													yIndex: this.currentPoint.yIndex,
													xAxis: String(option.series[i].data[subtractValue][
														0
													]),
													flag: true,
													lineStyle: {
														type: 'dashed'
													}
												};
												const xAxisObj1 = {
													xIndex: this.currentPoint.xIndex,
													yIndex: this.currentPoint.yIndex,
													xAxis: String(option.series[i].data[subtractValue1]
														[0]),
													flag: true,
													lineStyle: {
														type: 'dashed'
													}
												};
												this.xAxisData[i].push(xAxisObj, xAxisObj1);
											}
										}
									} else {
										this.xAxisArr[i] = [this.currentPoint.xIndex];
										this.xAxisData[i] = [xAxisObj];
									}
									var series = option.series[i];
									series.markLine = {
										lineStyle: {
											normal: {
												color: 'rgba(255, 38, 38, 1)'
											}
										},
										label: {
											color: '#ffffff',
											formatter: params => {
												if (params.data.flag) {
													if (params.dataIndex == 0) {
														return '(x=' + params.data.xValue + ',y=' +
															params.data.yValue + ',△f=' + params
															.data.f + ')';
													} else {
														return '';
													}
												} else {
													return '(x=' + params.data.xValue + ',y=' +
														params.data.yValue + ')';
												}
											}
										},
										data: this.xAxisData[i]
									};
								}
								break;
						}
						pinpuChats.setOption(option, true);
					});
				});
			},
			changeMark(val) {
				this.seriesPoints = [];
				this.xAxisData = [];
				this.xAxisArr = [];
				this.btnIndex = val;
				pinpuChats.getZr().off('click');
				if (val == 5) {
					this.resetChecked();
					this.init();
				}
			}
		}
	};
	/**
	 * @method
	 * @author  gedesiwen
	 * @param {array} arr 需要查找的数组
	 * @param {number} num 目标数值，查找的是与这个数值最接近的
	 * @return {number} 返回查找到的最接近的数值
	 * @desc 获取数组中与目标数值最接近的数值
	 */
	function findCloseNum(arr, num) {
		var index = 0; // 保存最接近数值在数组中的索引
		var d_value = Number.MAX_VALUE; // 保存差值绝对值，默认为最大数值
		for (var i = 0; i < arr.length; i++) {
			var new_d_value = Math.abs(arr[i] - num); // 新差值
			if (new_d_value <= d_value) { // 如果新差值绝对值小于等于旧差值绝对值，保存新差值绝对值和索引
				if (new_d_value === d_value && arr[i] < arr[index]) { // 如果数组中两个数值跟目标数值差值一样，取大
					continue;
				}
				index = i;
				d_value = new_d_value;
			}
		}
		return arr[index] // 返回最接近的数值
	}
</script>

<style lang="scss" scoped>
	.fontSize12{
		font-size: 12px;
	}
	.topTip {
		font-weight: 400;
		color: rgba(255, 255, 255, 0.65);
		font-size: 13px;
		position: absolute;
		left: 18px;
		top: 42px;
		width: 90%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
	}
	.chartDiv {
		.active {
			background-color: #2388ff;
		}

		.pinpuChats {
			height: 55vh;
			width: 100%;
			background-color: #000b17;
		}

		.pinpuChatsTree {
			// background-color: #dcdfe6;
			padding: 10px;
			height: 32vh;
			overflow-y: auto;
			border-radius: 8px;
			width: 94%;
			margin-left: 40px;
			border: 1px solid #bbbdc3;
			margin-top: 10px;

			.name {
				font-size: 18px;
				line-height: 40px;
				font-weight: bold;
			}

			// scrollbar-width: none; /* firefox */
			// -ms-overflow-style: none; /* IE 10+ */
		}

		// .pinpuChatsTree::-webkit-scrollbar {
		//     display: none; /* Chrome Safari */
		// }

		.pinpuChats.fsCharts {
			height: 55vh;
			width: 100%;
			background-color: #000b17;
		}

		.bg99 {
			background-color: #000b17;
		}

		.btnSome {
			height: 60px;
			width: 60px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			cursor: pointer;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}

		// .rightChange {
		//    background-color: #596067;
		// }
		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
		}
	}

	.rightClickMenu {
		/*这个样式不写，右键弹框会一直显示在画布的左下角*/
		position: absolute;
		background-color: rgb(255, 255, 255, 0.8);
		color: rgb(0, 0, 0, 1);
		border-radius: 5px;
		left: -99999px;
		top: -999999px;
	}

	.rightClickMenu>div>.el-button {
		cursor: pointer;
		margin: 0;
		padding: 5px 10px;
		font-size: 14px;
		display: block;
		width: 100%;
		color: #000000;
	}

	.rightClickMenu>div>.el-button:hover {
		background-color: rgb(64, 158, 255, 0.8);
	}

	.cellStyle {
		font-size: 12px;
		padding: 5px 0;
	}

	::v-deep .el-dialog {
		background-color: #ffffff !important;
		border-color: #ffffff !important;
	}

	::v-deep .el-dialog__header {
		text-align: center;
		color: #000000;
		padding: 10px;
	}

	::v-deep .el-dialog__title {
		color: #000000 !important;
	}

	::v-deep .el-input__inner {
		border-color: #409eff !important;
	}

	::v-deep .el-dialog__footer {
		padding: 10px 5%;
		border-color: none !important;
	}

	::v-deep .el-button {
		border-color: transparent !important;
	}

	::v-deep .el-tree-node__expand-icon::before {
		background-color: #ffffff !important;
	}

	::v-deep .el-checkbox__inner {
		border-color: #dcdfe6 !important;
	}
</style>