<template>
	<!-- 趋势分析 -->
	<div class="flex chartDiv" ref="chartDiv">
		<div class="TezhengfenxiChats" :class="isFullScreen?'fsCharts':''" ref="TezhengfenxiChats"></div>
		<div style="font-weight: 400;color: rgba(255, 255, 255, 0.65);font-size: 13px;position: absolute;left: 18px;">
			<img src="@assets/osImg/monitoring/tempBack.png" style="width:16px;height:16px;cursor:pointer"
				@click="$emit('tempBack')" v-if="canBack" />
			<span>数据来源：{{data.dataSrc==0?'圣名科技':(data.dataSrc==1?'西安因联':(data.dataSrc==2?'容知日新':''))}} </span>
		</div>
		<div class="bg99" style="color: #ffffff;">
			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.typesIndexList[0]  }" @click="changeTypes(0)">
					有效值
				</div>
				<div class="btnSome" :class="{ active: this.typesIndexList[1]   }" @click="changeTypes(1)">
					峰值
				</div>
				<div class="btnSome" :class="{ active:this.typesIndexList[2] }" @click="changeTypes(2)">
					峰峰值
				</div>
				<div class="btnSome" :class="{ active:this.typesIndexList[3] }" @click="changeTypes(3)">
					峭度
				</div>
				<div class="btnSome" :class="{ active:this.typesIndexList[4] }" @click="changeTypes(4)">
					偏度
				</div>
				<div class="btnSome" :class="{ active:this.typesIndexList[5] }" @click="changeTypes(5)">
					裕度
				</div>
				<div class="btnSome fontSize12" :class="{ active:this.typesIndexList[6] }" @click="changeTypes(6)">
					峰值因子
				</div>
				<div class="btnSome fontSize12" :class="{ active:this.typesIndexList[7] }" @click="changeTypes(7)">
					波形因子
				</div>
				<div class="btnSome fontSize12" :class="{ active:this.typesIndexList[8] }" @click="changeTypes(8)">
					脉冲指标
				</div>
				<div class="btnSome fontSize12" :class="{ active:this.typesIndexList[9] }" @click="changeTypes(9)">
					频谱峰值
				</div>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.chatsIndex == '加速度' }" @click="chatsChagne('加速度')">
					加速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '速度' }" @click="chatsChagne('速度')">
					速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '位移' }" @click="chatsChagne('位移')">
					位移
				</div>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">
					全屏
				</div>
				<div class="btnSome" v-else @click="exitFullScreen()">
					退出
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	let TezhengfenxiChats=null;
	let option={};
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	export default {
		name: "TezhengfenxiChats",
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return "";
				}
			},
			canBack: {
				type: Boolean,
				default () {
					return false;
				}
			}
		},
		data() {
			return {
				chatsIndex: this.activeMeasDef,
				isFullScreen: false,
				data: {},
				typesIndexList: [true, false, false, false, false,false,false, false, false,false],
				currentPoint: {}, 
			};
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.init();
			},
		},
		mounted() {
			let that = this;
			watchFull(this.$refs.chartDiv, ifs => {
				if (TezhengfenxiChats) {
					TezhengfenxiChats.resize();
				}
				if(!ifs){
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (TezhengfenxiChats) {
				TezhengfenxiChats.dispose();
				TezhengfenxiChats = null;
			}
			option = {};
		},
		methods: {
			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			changeTypes(index) {
				this.$set(this.typesIndexList, index, !this.typesIndexList[index]);
				let types = [];
				if (this.typesIndexList[0]) {
					types.push("有效值");
				}
				if (this.typesIndexList[1]) {
					types.push("峰值");
				}
				if (this.typesIndexList[2]) {
					types.push("峰峰值");
				}
				if (this.typesIndexList[3]) {
					types.push("峭度");
				}
				if (this.typesIndexList[4]) {
					types.push("偏度");
				}
				if (this.typesIndexList[5]) {
					types.push("裕度");
				}
				if (this.typesIndexList[6]) {
					types.push("峰值因子");
				}
				if (this.typesIndexList[7]) {
					types.push("波形因子");
				}
				if (this.typesIndexList[8]) {
					types.push("脉冲指标");
				}
				if (this.typesIndexList[9]) {
					types.push("频谱峰值");
				}
				this.$emit("tezhengTypesChange", types);
			},
			chatsChagne(chatsIndex) {
				if (chatsIndex) {
					this.chatsIndex = chatsIndex;
				}
				this.typesIndexList = [true, false, false, false, false,false,false, false, false,false];
				this.$emit('chatsChagne', this.chatsIndex)
			},
			initOption() {
				let series = [];
				for (let i in this.data.yData) {
					let obj = {
						data: this.data.yData[i].data,
						type: 'line',
						symbolSize: 1,
						showSymbol: false,
						showAllSymbol: false,
						itemStyle: {
							normal: {
								color: this.data.yData[i].color,
								lineStyle: {
									width: 0.8, // 0.1的线条是非常细的了
									color: this.data.yData[i].color,
								}
							}
						},
					};
					series.push(obj);
				}
				option = {
					tooltip: {
						confine: true, // 加入这一句话
						trigger: 'axis',
						backgroundColor: 'rgba(34, 34, 34, 0.5)',
						borderWidth: 0,
						textStyle: {
							color: 'rgba(255, 255, 255, 1.0)'
						},
						position: function(pos, params, el, elRect, size) {
							var obj = {
								top: 10
							};
							obj['left'] = pos[0] + 10;
							obj['top'] = pos[1] - 60;
							return obj;
						},
						formatter: (param) => {
							let arr = [];
							for (let i = 0; i < param.length; i++) {
								var item = param[i];
								var text = "";
								if (i == 0) {
									text += this.chatsData.data.yData[i].tooltip.x + "：" +
										item.name + this.chatsData.data.unit.x + "<br/>";
								}
								text +=
									"<div style='display:inline-block;width:10px;height:10px;background-color:" +
									item.color +
									";border-radius:10px'></div> " + this.chatsData.data.yData[i].name + "：" +
									item.data + this.chatsData.data.unit.y + "<br/>"
								arr.push(text);
							}
							return arr.join('');
						}
					},
					toolbox: {
						show: true,
						top: 20,
						right: 20,
						zlevel: 899,
						feature: {
							dataZoom: {
								yAxisIndex: 'none',
								brushStyle: {
									color: "rgba(35, 136, 255, 0.1)",
									shadowBlur: 2
								}
							},
							restore: {}
						}
					},
					title: {
						show: series[0].data.length>0?false:true, //show 可以在上面顶一个一个 let show = null;
						textStyle: {
					  color: '#fff',
							fontSize: 14,
							fontWeight: 100
						},
						text: '暂无数据', //这个你可以定义一个变量，也可以直接写死'暂无数据'
						left: 'center',
						top: 'center'
					},
					xAxis: {
						name: this.data.unit.x,
						type: "category",
						nameTextStyle: {
							color: "#D4D5D7"
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						axisLabel: {
							color: "#D4D5D7"
						},
						data: this.chatsData.data.xData[0].data
					},
					yAxis: {
						name: this.data.unit.y,
						nameTextStyle: {
							color: "#D4D5D7"
						},
						type: "value",
						splitLine: {
							show: true,
							lineStyle: {
								color: "#384049",
								type: 'dashed'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#384049"
							},
							onZero: false
						},
						axisLabel: {
							color: "#D4D5D7"
						},
					},
					legend: {
						icon: "pin",
						show: true,
						textStyle: {
							color: '#ffffff'
						},
						selected: {
							"有效值": true,
							"峰值": false,
							"峰峰值": false
						}
					},
					series: series,
					dataZoom: [{
							type: 'slider',
							show: true,
							xAxisIndex: [0],
							start: 0,
							end: 100,
							filterMode: 'empty',
						},
						{
							type: "inside",
							xAxisIndex: [0],
							start: 0,
							end: 100,
						},
					],
					grid: {
						top: "10%",
						left: "10px",
						right: "8%",
						bottom: "5%",
						containLabel: true,
					},
				};
				let max = 0;
				for (let i = 0; i < this.chatsData.data.yData.length; i++) {
					if (this.chatsData.data.yData[i].alarmShow == 1) {
						let warnData = [];
						for (let j = 1; j <= 3; j++) {
							let warnColor = `alarmColor${j}`;
							let warnLine = `alarmValue${j}`;
							let warnName = `alarmName${j}`;
							warnData.push({
								name: this.chatsData.data.yData[i][warnName],
								yAxis: this.chatsData.data.yData[i][warnLine],
								lineStyle: {
									type: 'solid',
									color: this.chatsData.data.yData[i][warnColor]
								},
								label: {
									color: "#ffffff",
									formatter: (params) => {
										return params.name + ":" + params.value;
									}
								}
							}, );
							if (max < parseFloat(this.chatsData.data.yData[i][warnLine])) {
								max = parseFloat(this.chatsData.data.yData[i][warnLine]);
							}
						}
						let yDataObj = {
							type: "line",
							smooth: true,
							markLine: {
								data: warnData,
							}
						};
						option.series.push(yDataObj);
					}
					for (let k = 0; k < this.chatsData.data.yData[i].data.length; k++) {
						let item = this.chatsData.data.yData[i].data[k];
						if (max < item) {
							max = item;
						}
					}
				}
				option.yAxis.max = max;
			},
			//点击空白处设置数据
			clickZr(params, func) {
				const pointInPixel = [params.offsetX, params.offsetY];
				this.seriesPoints = [];
				//对series遍历
				let index = TezhengfenxiChats.convertFromPixel({
					seriesIndex: 0
				}, [params.offsetX, params.offsetY]);
				let xIndex = index[0];
				let yIndex = index[1];
				let op = option;
				// 获取当前点击位置对应折线上的数据
				var xData = op.xAxis.data[xIndex]
				var yData = op.series[0].data[xIndex]
				//记录所有坐标系的点
				var pointObj = {
					name: xData,
					value: yData,
					xIndex: xIndex,
					yIndex: yIndex,
					seriesIndex: 0
				}
				this.seriesPoints.push(pointObj);
				//单独记录所点坐标系的点
				if (TezhengfenxiChats.containPixel({
						seriesIndex: 0
					}, pointInPixel)) {
					this.currentPoint.name = xData;
					this.currentPoint.value = yData;
					this.currentPoint.xIndex = xIndex;
					this.currentPoint.yIndex = yIndex;
					this.currentPoint.seriesIndex = 0;
				}
				func();
			},
			init() {
				this.data = this.chatsData.data;
				TezhengfenxiChats = this.$echarts.init(this.$refs.TezhengfenxiChats);
				this.initOption();
				TezhengfenxiChats.setOption(option, true);
				TezhengfenxiChats.getZr().off("click");
				TezhengfenxiChats.getZr().on("click", (params) => {
					this.clickZr(params, () => {
						if (this.seriesPoints.length > 0 && this.seriesPoints[0].name) {
							this.$emit("clickPoint", this.seriesPoints[0].name, this.chatsIndex);
						}
					})
				});
			},
		},
	};
</script>
<style lang='scss' scoped>
	.chartDiv {
		.TezhengfenxiChats {
			height: 85vh;
			width: 100%;
		}

		.TezhengfenxiChats.fsCharts {
			height: 100vh;
			width: 100%;
			background-color: #000B17;
		}

		.controlFrame-item {
			width: 150px
		}

		.active {
			background-color: #2388ff;
		}

		.timeDomainsChats {
			height: 80vh;
			width: 100%;
			background-color: #000B17;
		}

		.bg99 {
			background-color: #000B17;
		}

		.btnSome {
			height: 50px;
			line-height: 50px;
			color: #fff;
			text-align: center;
			cursor: pointer;
		}
		.fontSize12{
			font-size: 12px;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}


		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
			box-sizing: border-box;
			width: 60px;
		}

	}
</style>
