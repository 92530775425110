<template>
	<!-- 热力图 -->
	<div class="flex chartDiv" ref="chartDiv">
		<div class="waterfallChats" :class="isFullScreen?'fsCharts':''" ref="waterfallChats"></div>
		<div class="bg99" style="color: #ffffff;">

			<div class="rightBtn mt-10">
				<div class="btnSome" :class="{ active: this.chatsIndex == '加速度' }" @click="chatsChagne('加速度')">
					加速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '速度' }" @click="chatsChagne('速度')">
					速度
				</div>
				<div class="btnSome" :class="{ active: this.chatsIndex == '位移' }" @click="chatsChagne('位移')">
					位移
				</div>
			</div>
			<div class="rightBtn mt-10">
				<div class="btnSome" v-if="!isFullScreen" @click="fullScreen()">
					全屏
				</div>
				<div class="btnSome" v-else @click="exitFullScreen()">
					退出
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var option = {};
	var waterfallChats = null;
	import {
		beFull,
		exitFull,
		watchFull
	} from 'be-full';
	export default {
		name: "waterfallChats",
		props: {
			chatsData: {
				type: Object,
				default () {
					return {};
				}
			},
			activeMeasDef: {
				type: String,
				default () {
					return "";
				}
			},
		},
		data() {
			return {
				chatsIndex: this.activeMeasDef,
				isFullScreen: false,
			};
		},
		watch: {
			activeMeasDef(val) {
				this.chatsIndex = val;
			},
			chatsData(val) {
				this.init();
			},
		},
		mounted() {
			let that = this;
			watchFull(this.$refs.chartDiv, isFullScreen => {
				if (waterfallChats) {
					waterfallChats.resize();
				}
				if (!isFullScreen) {
					that.isFullScreen = false;
				}
			});
		},
		beforeDestroy() {
			if (waterfallChats) {
				waterfallChats.dispose();
				waterfallChats = null;
			}
			option = {};
		},
		methods: {
			//全屏
			fullScreen() {
				this.isFullScreen = true;
				beFull(this.$refs.chartDiv);
			},
			//退出全屏
			exitFullScreen() {
				this.isFullScreen = false;
				exitFull();
			},
			chatsChagne(chatsIndex) {
				if (chatsIndex) {
					this.chatsIndex = chatsIndex;
				}
				this.$emit('chatsChagne', this.chatsIndex)
			},
			init() {
				var seriesData = []
				this.chatsData.data.series.forEach(item=>{
					let obj = {
						type: 'line3D',
						data: item,
						lineStyle: {
							width: 1
						}
					}
					seriesData.push(obj)
				})
				waterfallChats = this.$echarts.init(this.$refs.waterfallChats);
				option = {
						tooltip: {
							backgroundColor: 'rgba(34, 34, 34, 0.5)',
							borderWidth: 0,
							textStyle: {
								color: 'rgba(255, 255, 255, 1.0)'
							},
							axisPointer: {
								animation: false
							},
							position: 'top',
							formatter: (param) => {
								return [
									this.chatsData.data.tooltip[0].x + "：" + param.value[0] + this.chatsData.data
									.unit[0]
									.x +
									"<br/>",
									this.chatsData.data.tooltip[0].y + "：" + this.chatsData.data.name[param.seriesIndex] + this
									.chatsData.data.unit[0]
									.y +
									"<br/>",
									this.chatsData.data.tooltip[0].z + "：" + param.value[2] + this.chatsData.data
									.unit[0]
									.z
								].join('');
							}
						},
					visualMap: {
						show: false,
						dimension: 2,
						min: this.chatsData.data.calc[0].min,
						max: this.chatsData.data.calc[0].max,
						inRange: {
							color: ["#3bb156", "#ff0000"]
						},
					},
					xAxis3D: {
						type: 'value',
						name: this.chatsData.data.unit[0].x,
						nameTextStyle: {
							color: '#ffffff'
						},
						axisLabel: {
							textStyle: {
								color: "#ffffff"
							}
						},
						axisPointer: {
							lineStyle: {
								color: "#25beff"
							}
						},
						splitLine:{
							lineStyle: {
								color: "#999999",
								width:1
							}
						},
						axisLine:{
							lineStyle: {
								color: "#ffffff",
								width:1
							}
						}

					},
					yAxis3D: {
						type: 'category',
						name: '时间',
						axisLabel: {
							textStyle: {
								color: "#ffffff"
							}
						},
						nameTextStyle: {
							color: '#ffffff'
						},
						axisPointer: {
							lineStyle: {
								color: "#25beff"
							}
						},
						splitLine:{
							lineStyle: {
								color: "#999999",
								width:1
							}
						},
						axisLine:{
							lineStyle: {
								color: "#ffffff",
								width:1
							}
						},
						data: this.chatsData.data.name,
						nameGap:30
					},
					zAxis3D: {
						type: 'value',
						name: this.chatsData.data.unit[0].z ,
						axisLabel: {
							textStyle: {
								color: "#ffffff"
							}
						},
						nameTextStyle: {
							color: '#ffffff'
						},
						axisPointer: {
							lineStyle: {
								color: "#25beff"
							}
						},
						splitLine:{
							lineStyle: {
								color: "#999999",
								width:1
							}
						},
						axisLine:{
							lineStyle: {
								color: "#ffffff",
								width:1
							}
						}
					},
					grid3D: {
						viewControl: {
							projection: 'orthographic'
						}
					},
					series: seriesData
				};
				waterfallChats.setOption(option, true);
			},
		},
	};
</script>

<style lang='scss' scoped>
	.chartDiv {
		.waterfallChats {
			height: 85vh;
			width: 100%;
		}

		.waterfallChats.fsCharts {
			height: 100vh;
			width: 100%;
			background-color: #000B17;
		}

		.controlFrame-item {
			width: 150px
		}

		.active {
			background-color: #2388ff;
		}

		.timeDomainsChats {
			height: 80vh;
			width: 100%;
			background-color: #000B17;
		}

		.bg99 {
			background-color: #000B17;
		}

		.btnSome {
			height: 60px;
			width: 60px;
			color: #fff;
			text-align: center;
			line-height: 60px;
			cursor: pointer;
		}

		.btnSome:hover {
			opacity: 1;
			color: #aaa;
		}


		.rightBtn {
			background-color: #596067;
			border-radius: 8px;
			overflow: hidden;
			width: 60px;
			margin-left: 90px;
		}

	}
</style>